<template>
  <div id="OrderForm">
    <van-tabs v-model="activeType" @click="onClick" @disabled="onClickDisabled">
      <van-tab title="全部" name="5">

      </van-tab>
      <van-tab title="订单查询" name="1">
        <!-- <div class="posFix">
          <div class="listFirst">
            <div class="list" @click.stop="toProgrammeDetail">
              <div>
                <img  src="../../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂23:04:57</div>
                  <div>
                    <span>上海</span>
                  </div>
                  <div>
                    <span>06/18（周五）</span>
                    <span @click.stop="toForms">免费报名</span>
                  </div>
                </div>
              </div>
              <img  src="../../assets/image/findOut/gxIng.png"/>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="list" @click.stop="toProgrammeDetail">
              <div>
                <img  src="../../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂23:04:57</div>
                  <div>
                    <span>上海</span>
                  </div>
                  <div>
                    <span>06/18（周五）</span>
                    <span @click.stop="toForms">立即学习</span>
                  </div>
                </div>
              </div>
              <img  src="../../assets/image/findOut/gxIng.png"/>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="待支付" name="2" >
        <!-- <div class="posFix">
          <div class="listTwo">
            <div class="list" @click.stop="toCoursesDetail">
              <div>
                <img src="../../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂</div>
                  <div><span>待支付</span>23:04:57</div>
                  <div>
                    <span>¥899<span>38人已学习</span></span>
                  </div>
                </div>
                <p>
                  <span>去支付</span>
                  <span>取消订单</span>
                </p>
              </div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="list" @click.stop="toCoursesDetail">
              <div>
                <img src="../../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂</div>
                  <div><span>待支付</span>23:04:57</div>
                  <div>
                    <span>¥899<span>38人已学习</span></span>
                  </div>
                </div>
                <p>
                  <span>去支付</span>
                  <span>取消订单</span>
                </p>
              </div>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div> -->
      </van-tab>
      <!-- <van-tab title="已预约" name="3"  > -->
        <!-- <div class="posFix">
           <div class="listThree">
            <div class="list" @click.stop="toProgrammeDetail">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span @click.stop="toForms" class="now">正在直播</span>
                </div>
              </div>
            </div>
            <div class="list">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span>免费报名</span>
                </div>
              </div>
            </div>
            <div class="list">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span class="active">已结束</span>
                </div>
              </div>
            </div>
          </div>
        </div>  -->
      <!-- </van-tab> -->
      <!-- <van-tab title="待参加" name="4" > -->
        <!-- <div class="posFix">
          <div class="listThree">
            <div class="list" @click.stop="toProgrammeDetail">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                  <span>上海</span>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span @click.stop="toForms">免费报名</span>
                </div>
              </div>
            </div>
            <div class="list">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                  <span>上海</span>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span>免费报名</span>
                </div>
              </div>
            </div>
            <div class="list">
              <img src="../../assets/image/home/index1.png"/>
              <div>
                <div >保观学堂23:04:57</div>
                <div>
                  <span>上海</span>
                </div>
                <div>
                  <span>06/18（周五）</span>
                  <span class="active">已结束</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </van-tab> -->
    </van-tabs>

    <div>
      <van-popup v-model="show" position="top" style="width:100%">
        <div class="topPop">
          <div class="titleTop2 interests">选择你感兴趣的话题</div>
          <div class="titleTab">
            <div class="active">全部</div><div>团险</div><div>MGA</div><div>全部</div><div>健康险</div><div>全部</div><div>全部</div><div>全部</div><div>全部</div>
          </div>
          <div class="anscontain"><span>重置</span><span>完成</span></div>
        </div>
      </van-popup>
      <van-popup v-model="letters" position="top" style="width:100%">
        <div class="sort">
          <p class="active">智能排序</p>
          <p>按时间升序</p>
          <p>按时间降序</p>
          <p>按下载量升序</p>
          <p>按下载量降序</p>
        </div>
      </van-popup>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="posFix"
    >
      <div v-show="activeType == 5">
        <div v-for="(i,item) in getDocList" :key="item" :class="{'listFirst':i.pay_status == 2 || i.pay_status == 5,'listTwo':i.pay_status == 1}">
          <div class="list"  v-if="i.pay_status == 2 || i.pay_status == 5">  <!-- 支付成功 -->
            <div v-if="i.productInfo">
              <img v-if="i.type == 1 || i.type == 2 || i.type == 6" src="../../assets/image/home/index2.png"/>
              <img v-else src="../../assets/image/home/index1.png" />
              <div>
                <div >{{i.productInfo.name}}</div>
                <div><span v-if="i.type == 5">上海</span></div>
                <div>
                  <span>{{Global.fromatTimestamp(i.created_at).split(' ')[0]}}（{{getWeek($moment(i.created_at).day())}}）</span>
                  <span class="todetail" v-if="i.type == 3" @click="toCoursesDetail(i.link_id)">查看详情</span>
                  <span class="todetail" v-else-if="i.type == 5" @click="toProgrammeDetail(i.link_id)">查看详情</span>
                </div>
              </div>
            </div>
            <img v-if="i.type == 3"  src="../../assets/image/findOut/gxIng.png"/>
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="list" v-else>
            <div v-if="i.productInfo">
              <img v-if="i.type == 1 || i.type == 2 || i.type == 6" src="../../assets/image/home/index2.png"/>
              <img v-else src="../../assets/image/home/index1.png" />
              <div>
                <div >{{i.productInfo.name}}</div>
                <div><span>待支付</span></div>
                <div>
                  <span >¥{{i.total_fee}}<span v-if="i.type == 3">{{i.learnCount}}人已学习</span></span>
                </div>
              </div>
              <p class="two" v-if="isOut(i.created_at,item)">
                <span @click="toBuy(i.id)">去支付</span>
                <span @click="cancels(i.id,item)">取消订单</span>
              </p >
              <p v-else class="over">
                <span>已过期</span>
              </p>
            </div>
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="listFirst" v-show="activeType == 1">
        <div class="list"  v-for="(i,item) in getDocList" :key="item">
          <div v-if="i.productInfo">
            <img v-if="i.type == 1 || i.type == 2 || i.type == 6" src="../../assets/image/home/index2.png"/>
            <img v-else src="../../assets/image/home/index1.png" />
            <div>
              <div >{{i.productInfo.name}}</div>
              <div>
                <span v-if="i.type == 5">上海</span>
              </div>
              <div>
                <span>{{Global.fromatTimestamp(i.created_at).split(' ')[0]}}（{{getWeek($moment(i.created_at).day())}}）</span>
                <span class="todetail" v-if="i.type == 3" @click="toCoursesDetail(i.link_id)">查看详情</span>
                <span class="todetail" v-else-if="i.type == 5" @click="toProgrammeDetail(i.link_id)">查看详情</span>
              </div>
            </div>
          </div>
          <img v-if="i.type == 3"  src="../../assets/image/findOut/gxIng.png"/>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="listTwo"  v-show="activeType == 2">
        <div class="list" v-for="(i,item) in getDocList" :key="item">
          <div v-if="i.productInfo">
            <img v-if="i.type == 1 || i.type == 2 || i.type == 6" src="../../assets/image/home/index2.png"/>
            <img v-else src="../../assets/image/home/index1.png" />
            <div>
              <div >{{i.productInfo.name}}</div>
              <div><span>待支付</span></div>
              <div>
                <span >¥{{i.total_fee}}<span v-if="i.type == 3">{{i.learnCount}}人已学习</span></span>
              </div>
            </div>
            <p class="two" v-if="isOut(i.created_at,item)">
              <span @click="toBuy(i.id)">去支付</span>
              <span @click="cancels(i.id,item)">取消订单</span>
            </p >
            <p v-else class="over">
              <span>已过期</span>
            </p>
          </div>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="listThree"  v-show="activeType == 3">
        <div v-for="(i,item) in getDocList" :key="item">
          <div class="list"  v-if="i.live">
            <img :src="Global.changeImg(i.live.thumbimgurl)"/>
            <div>
              <div >{{i.live.title}}</div>
              <div>
              </div>
              <div>
                <span>{{i.live.livetime_start.split(' ')[0]}}（{{getWeek($moment(i.live.livetime_start).day())}}）</span>

                <span class="active" @click="$toast('已结束')" v-if="Date.parse(new Date())>Date.parse(new Date((i.live.livetime_end).replace(/-/g, '/')))">已结束</span>
                <span class="order" @click="toLiveDetail(i.id)" v-else-if="Date.parse(new Date())<Date.parse(new Date((i.live.livetime_start).replace(/-/g, '/')))">未开始</span>
                <span class="now" @click="toLiveDetail(i.live.id)" v-else-if="Date.parse(new Date())>Date.parse(new Date((i.live.livetime_start).replace(/-/g, '/'))) && Date.parse(new Date())<Date.parse(new Date((i.live.livetime_end).replace(/-/g, '/')))">直播中</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="listThree" v-show="activeType == 4">
        <div v-for="(i,item) in getDocList" :key="item">
          <div class="list"  v-if="i.activity">
            <img :src="Global.changeImg(i.activity.thumbimgurl)"/>
            <div>
              <div >{{i.activity.title}}</div>
              <div>
                <span>{{i.activity.activity_location}}</span>
              </div>
              <div>
                <span>{{i.activity.deadline.split(' ')[0]}}（{{getWeek($moment(i.activity.deadline).day())}}）</span>
                <span @click="$toast('已结束')"  v-if="i.activity.sign == 3" style="background: #9091A3;">已结束</span>
                <span v-else @click.stop="toProgrammeDetail(i.activity_id)" >已报名</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    
    <van-popup v-model="showQrCode" style="background:none">
      <img class="main" src="img/kf3.png" />
      <div class="close">
        <span @click="showQrCode = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup>
  </div>
</template>
<script>
// 1-会员 2-体验会员 3-课程 4-报告 5-活动报名 6:认证会员
export default {
  name: 'OrderForm',
   data(){
    return{
      show:false,
      showQrCode: false,
      letters:false,
      value:'',
      activeType:'',
      finished:false,
      loading:true,
      next_page_url:'',
      getDocList:'',
      xx:'',
      isPay:false,
      product_id:''
    }
  },
  mounted(){
    this.first()
  },
  methods:{
    first(){
      this.activeType = this.$route.query.type  //选项
      var url;
      if(this.activeType == '1' || !this.activeType){ //订单查询
        url = '/user/userOrder'
      }else if(this.activeType == '2'){ //待支付
        url = '/user/stayPay'
      }else if(this.activeType == '3'){ //已预约
        url = '/live/subscribeLive'
      }else if(this.activeType == '4'){ //待参加
        url = '/activity/stayActivity'
      }else if(this.activeType == '5'){ //全部
        url = '/user/userAllOrder'
      }
      window.scrollTo(0,0)

      // 订单列表
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        async:false
      }).then((res) => {
        if(res.data.code == 0){
          this.getDocList = res.data.data.data;
          this.loading = false
          this.next_page_url = res.data.data.next_page_url
        }
      })
    },
    // 24小时过期
    isOut(ts){
        let date = (Date.parse(new Date())-Date.parse(new Date(Date.parse(ts))))/1000;
        var y = Math.floor(date/3600); 
        if( y > 24){
          return ''
          // this.getDocList[i].outTime = 0
        }else{
          return 1
        }
        // else{
        //   let dats = (Date.parse(new Date(Date.parse(ts)))-Date.parse(new Date()))/1000 + 34*3600;
        //   var m = dats%3600  
        //   var d = Math.floor(m/60);
        //   var e = m%60
        //   var _this = this;
        //   this.xx = setTimeout(function(){
        //     clearTimeout(_this.xx)
        //     _this.getDocList[i].outTime = y + ':' + d + ':' +e
        //     console.log(1)
        //     // _this.getDocList = JSON.parse(JSON.stringify(_this.getDocList))
        //     _this.isOut(ts,i)
        //   },1000)
        // }
    },
    onClick(name, title){
      console.log(name,title,'????')
      // this.$router.push({path:'/OrderForm?type='+name})
      this.activeType = name
      this.firstLoad()
    },
    firstLoad(){
      window.scrollTo(0,0)
      var url;
      console.log(this.activeType,!this.activeType)
      if(this.activeType == '1' || !this.activeType){
        url = '/user/userOrder'
      }else if(this.activeType == '2'){ //待支付
        url = '/user/stayPay'
      }else if(this.activeType == '3'){ //已预约
        url = '/live/subscribeLive'
      }else if(this.activeType == '4'){ //待参加
        url = '/activity/stayActivity'
      }else if(this.activeType == '5'){ //全部
        url = '/user/userAllOrder'
      }
      this.finished = false
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    toCoursesDetail(id){
      this.$router.push({
        path: '/CoursesDetail?seriesId='+id,
      })
    },

    toLiveDetail(id){
      this.$router.push({
        path: '/LiveDetail?id='+id,
      })
    },
    // 活动订单  进入订单详情   1-会员 2-体验会员 3-课程 4-报告 5-活动报名 6:认证会员
    toProgrammeDetail(activityId){
      this.$router.push({
        path: '/ProgrammeDetail?activityId='+activityId,
      })
    },
    
    focusChange(){
      // this.$router.push({
      //   path: '/SearchFor',
      // })
    },
    onClickDisabled(){
      this.$toast('暂未开放');
    },
    getWeek(a){
      switch(a){
        case 1:
        return '周一';
        case 2:
        return '周二';
        case 3:
        return '周三';
        case 4:
        return '周四';
        case 5:
        return '周五';
        case 6:
        return '周六';
        case 0:
        return '周日';
      }
    },
    toBuy(id){
      this.isPay = false
      this.product_id = id
      this.prepay()
    },
    cancels(id,index){
      this.axios({
        method: 'PATCH',
        url: '/user/stayPayCancel/'+id,
        headers: {
          'Authorization': `Bearer ${this.Global.getCookie('token')}` 
        },
      }).then( (res) => {
        if(res.data.code == 0){
          //免费
          this.$toast('取消成功！')
          this.getDocList.splice(index,1)
        }
      })
    },
    prepay(){
      if( !this.isPay ){
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/stayOrderPay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            orderId: this.product_id,
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              // setTimeout(() => {
              //   this.$router.push('/SignUpEnd?type=c&activityId='+this.activityId)
              // }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.isPay = 0
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            // 显示客服二维码
            this.showQrCode = true
            this.onClick(1,'待支付')
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
  },
  beforeDestroy(){
    var _this = this;
    clearTimeout(_this.xx)
  },
  // watch: {
  //   '$route': 'first'
  // }
}
</script>
<style lang="less" scoped>
#OrderForm{
  .main{
    width: 2.63rem;
  }
  .close{
    text-align: center;
    >span{
      width: 0.35rem;
      height: 0.35rem;
      background: #6e6f72;
      border-radius: 50%;
      margin-top: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.2rem;
    }
  }
  .posFix{
    border-top:1px solid #5C5D6E61;
    padding-top: 0.14rem;
  }
  .listFirst{
    padding: 0 0.15rem;
    background: #23252E;
    >div{
      padding: 0.15rem 0.13rem 0.15rem 0.18rem;
      background: #3D404F;
      margin-bottom: 0.11rem;
      position: relative;
      >div:first-child{
        display: flex;
        align-items: center;
        >img{
          max-width: 0.81rem;
          height: 0.53rem;
          margin-right: 0.12rem;
        }
        >div{
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
          color: #F6F7FD;
          >div:last-child{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:first-child{
              color: #989AB1;
              font-size: 0.12rem;
            }
            >span.todetail{
              height: 0.25rem;
              background: #3890FF;
              color: #FFFFFF;
              text-align: center;
              line-height: 0.25rem;
              padding: 0 0.13rem;
              font-size: 0.12rem;
              border-radius: 0.25rem;
              transform: scale(0.8);
            }
          }
          >div:nth-child(2){
            color: #727387;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.05rem 0 0;
            height: 0.14rem;
          }
          >div:first-child{
            color: #F6F7FD;
            font-size: 0.13rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      >div:last-child{
        position:absolute;
        width: 100%;
        height: 0.2rem;
        display: flex;
        justify-content: space-between;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-left: -0.18rem;
        >span{
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background:#23252E;
        }
        >span:first-child{
          margin-left: -0.1rem;
        }
        >span:last-child{
          margin-right: -0.1rem;
        }
      }
      >img{
        width: 0.3rem;
        position: absolute;
        top: 0;
        right: 0.18rem;
      }
    }
  }
  .listThree{
    padding: 0 0.15rem;
    background: #23252E;
    >div{
      .blue{
        color: #3890FF;
      }
      >div:last-child{
        border: none;
      }
      >div{
        padding: 0.15rem 0;
        display: flex;
        align-items: center;
        border-bottom:.1px solid #5C5D6E61;
        >img{
          max-width: 0.81rem;
          height: 0.53rem;
          margin-right: 0.12rem;
        }
        >div{
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
          color: #F6F7FD;
          >div:last-child{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:first-child{
              color: #989AB1;
              font-size: 0.12rem;
            }
            >span:last-child{
              height: 0.25rem;
              background: #3890FF;
              color: #FFFFFF;
              text-align: center;
              line-height: 0.25rem;
              padding: 0 0.13rem;
              font-size: 0.12rem;
              border-radius: 0.25rem;
              transform: scale(0.9);
            }
            >span.active{
              background: #9091A3;
            }
            >span.ordedr{
              background: linear-gradient(270deg, #3C94FF 0%, #4CADFF 100%);
            }
            >span.now{
              background: linear-gradient(270deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);
            }
            >span.eye{
              background: none;
              color: #727387;
              transform: none;
            }
          }
          >div:nth-child(2){
            color: #727387;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0.04rem 0 0.04rem;
            height: 0.14rem;
          }
          >div:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

  }
  .listTwo{
    padding: 0 0.15rem;
    background: #23252E;
    >div{
      padding: 0.15rem 0.12rem 0.15rem 0.18rem;
      background: #3D404F;
      margin-bottom: 0.11rem;
      position: relative;
      >div:first-child{
        display: flex;
        align-items: center;
        >img{
          max-width: 0.81rem;
          height: 0.53rem;
          margin-right: 0.12rem;
        }
        >div{
          display: flex;
          flex-direction: column;
          flex: 1;
          color: #F6F7FD;
          >div:last-child{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:first-child{
              color: #FF4A4A;
              font-size: 0.12rem;
              >span{
                color: #989AB1;
                margin-left: 0.05rem;
                font-size: 0.12rem;
                transform: scale(0.8);
              }
            }
          }
          >div:nth-child(2){
            color: #9091A3;
            font-size: 0.12rem;
            margin: 0.05rem 0 0.06rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            >span{
              color: rgba(252, 169, 26, 0.58);
              margin-right: 0.05rem;
            }
          }
          >div:first-child{
            display: flex;
            color: #F6F7FD;
            font-size: 0.14rem;
          }
        }
        >p.two{
          display: flex;
          flex-direction: column;
          >span:first-child{
            background: #3890FF;
            color: #FFFFFF;
          }
          >span:last-child{
            color: #3890FF;
            border: 1px solid #3890FF;
            margin-top: 0.05rem;
          }
          >span{
            height: 0.25rem;
            text-align: center;
            line-height: 0.25rem;
            padding: 0 0.13rem;
            font-size: 0.12rem;
            border-radius: 0.25rem;
            transform: scale(0.8);
            box-sizing: border-box;
          }
        }
        >p.over{
          display: flex;
          >span{
            height: 0.25rem;
            text-align: center;
            line-height: 0.25rem;
            padding: 0 0.13rem;
            font-size: 0.12rem;
            border-radius: 0.25rem;
            transform: scale(0.8);
            box-sizing: border-box;
            background: #9091A3;
            color: #F6F7FD;
          }
        }
      }
      >div:last-child{
        position:absolute;
        width: 100%;
        height: 0.2rem;
        display: flex;
        justify-content: space-between;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-left: -0.18rem;
        >span{
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background:#23252E;
        }
        >span:first-child{
          margin-left: -0.1rem;
        }
        >span:last-child{
          margin-right: -0.1rem;
        }
      }
  
      
    
    }
  }
  .sort{
    padding: 0.15rem 0.3rem 0.05rem;
    >p{
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.12rem;
    }
    .active{
      color: #3890FF;
    }
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    padding-left: 0.15rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.1rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  }
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  background: #23252E;
}
</style>